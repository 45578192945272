import '../scss/app.scss';

// Your JS Code goes here
//import Hero from '../components/hero/hero.js'
import Modal from '../components/modal/modal.js'
import barba from '@barba/core';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { gsap } from "gsap";

AOS.init();

//console.log(AOS)

/*let heroEl = document.querySelector(".hero");
let hero = new Hero();
hero.init(heroEl)*/

const initModal = () => {
  let modalEl = document.querySelector(".modal");
  let modal = new Modal();
  modal.init(modalEl)
}
initModal()

barba.init({
	preventRunning: true,
	debug: true,
	logLevel: 'debug',
	transitions: [
	/*{
	  name: 'fromHome',
	  from: { //this transition is only applied when navigating FROM home
			namespace: ['home']
	  },
	  leave(data) {
		return gsap.to(data.current.container, {
		  opacity: 0.5,
		  duration: 0.5,
		  y: 200,
		  delay: 1.9 //this transition has a longer delay
		});
	  },
	  enter(data) {
		return gsap.from(data.next.container, {
		  opacity: 0,
		  duration: 0.5,
		  y: 200,
		  delay: 0.5,
		  onStart: entering(data)
		});
	  }
	},*/
	{ //last defined transition is the default:
		name: 'opacity-transition',
		leave(data) {
		  return gsap.to(data.current.container, {
			opacity: 0,
			duration: 0.5,
			y: 200,
			delay: 0
		  });
		},
		enter(data) {
		  return gsap.from(data.next.container, {
			opacity: 0,
			duration: 0.5,
			y: 200,
			delay: 0.5,
			onStart: entering(data)
		  });
		}
	  }
	]
});


function entering(data) {
  let currentPage = data.next.namespace;
  window.scrollTo(0, 0);
  initModal()
}
