export default class Modal {
    init(root) {
        this.root = root
        this.render()
    }

    render() {
      let close = document.querySelector('.modal-close')
      let embedWrapper = this.root.querySelector('.modal-embed')
      let textWrapper = this.root.querySelector('.modal-text')
      let thumbnail = this.root.querySelector('.modal-thumbnail')

      const openModal = (embed, text, tumbnail, aspectRatio) => {
        this.root.classList.add('is-visible')
        document.querySelector('.container').classList.add('blur')
        document.querySelector('body').style.overflow = "hidden"

        //close button
        close.style.opacity = 1
        close.style.pointerEvents = "auto"

        //add content
        textWrapper.innerHTML = text
        embedWrapper.innerHTML = embed + tumbnail

        //aspect ratio keeper – with updated value from iframe ratio
        var div = document.createElement('div');
        div.className = 'modal-aspect-ratio';
        div.style.paddingBottom = aspectRatio + "%"
        embedWrapper.appendChild(div);

        embedWrapper.style.maxWidth = 'calc((100vh - 2rem) /' + aspectRatio + '* 100)'

        //resize thumbnail to aspect ratio
        //let embedH = this.root.offsetHeight
        //thumbnail.style.width = embedH / aspectRatio
      }

      const closeModal = () => {
        this.root.classList.remove('is-visible')
        document.querySelector('.container').classList.remove('blur')
        document.querySelector('body').style.overflow = "auto"
        close.style.opacity = 0
        close.style.pointerEvents = "none"
        setTimeout(function(){
          if(!document.querySelector('.container').classList.contains('blur')) { //check if still closed
            embedWrapper.innerHTML = " "
            textWrapper.innerHTML = " "
          }
        }, 200);
        this.root.scrollTop = 0;
      }

      close.addEventListener('click', (e) => {
        closeModal();
      })

      document.querySelectorAll('.project-teaser').forEach((teaser) => {
        teaser.addEventListener('click', (e) => {
          let target = e.target
          let targetEmbed = target.querySelector('.project-teaser_embed').dataset.embed
          let targetAspectRatio = target.querySelector('.project-teaser_embed').dataset.aspectratio
          let targetText = target.querySelector('.project-teaser_text').dataset.text
          let targetThumbnail = target.querySelector('.project-teaser_thumbnail').dataset.thumbnail
          openModal(targetEmbed, targetText, targetThumbnail, targetAspectRatio)
        })
      })
    }
}
